<template>
    <div v-if="isShow">
        <router-link tag="span" :to="resolvePath()" v-if="!item.children">
            <el-menu-item v-show="!item.meta.notShow" :index="resolvePath()">
                <i :class="item.meta.icon"></i>
                <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
        </router-link>

        <el-submenu :index="resolvePath()" v-else>
            <template slot="title">
                <i :class="item.meta.icon"></i>
                <span slot="title">{{ item.meta.title }}</span>
            </template>
            <menu-item v-for="(route, index) in item.children" :key="index" :item="route" :fatherPath="resolvePath(route.path)">
            </menu-item>
        </el-submenu>
    </div>
</template>
  
  <script>
import path from "path";

export default {
    name: "MenuItem",
    props: {
        item: {
            type: Object,
            default: null,
        },
        fatherPath: {
            type: String,
            default: "",
        }
        
    },
    data() {
        return {role:'',isShow:false};
    },
    methods: {
        resolvePath(routePath = "") {
            return path.resolve(this.fatherPath, routePath);
        },
        
    },
    mounted () {
        this.role = localStorage.getItem("role")
        if(this.role === "1"){
            this.isShow = true
        }
        if(this.role === "2" && this.item.meta.title === 'User' || this.item.meta.title === 'Home' || this.item.meta.title === 'User Order Prop' || this.item.meta.title === 'OrderSelectList'){
            this.isShow = true 
        }       
    },
};
</script>
  